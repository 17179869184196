import { IBillingInfo, ICompany, IApplication, IStateSteps, IUser, IBackendState, ITariffPlan } from './state-steps-service.interface';

import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ConfigServiceInterface } from '../../../../core/services/config/config-service.interface';
import { IRegisterUser } from '../register/register-service.interface';

@Injectable()
export class StateStepsService implements IStateSteps {
  private localStorageName = 'p500:stateSteps';
  private userState: IUser;
  private companyState: ICompany;
  private applicationState: IApplication;
  private billingInfoState: IBillingInfo;
  private stepState: string;
  private userInputDataValue: IRegisterUser;
  private tariffPlanState: ITariffPlan;

  constructor(@Inject('ConfigServiceInterface') private configService: ConfigServiceInterface, private http: HttpClient) {}

  updateState(): void {
    const state = {
      userInputData: this.userInputData,
      user: this.userState,
      company: this.companyState,
      billingInfo: this.billingInfoState,
      application: this.applicationState,
      step: this.stepState,
      tariffPlan: this.tariffPlanState,
    };

    localStorage.setItem(this.localStorageName, JSON.stringify(state));
  }

  get userInputData(): IRegisterUser {
    return this.userInputDataValue;
  }

  setUserInputData(userData: IRegisterUser): void {
    this.userInputDataValue = userData;
    this.updateState();
  }

  get user(): IUser {
    return this.userState;
  }

  setCompany(companyData: ICompany): void {
    this.companyState = { ...companyData };
    this.updateState();
  }

  get company(): ICompany {
    return this.companyState;
  }

  setBillingInfo(billingInfoData: IBillingInfo): void {
    this.billingInfoState = {
      ...this.billingInfoState,
      ...billingInfoData,
    };
    this.updateState();
  }

  get billingInfo(): IBillingInfo {
    return this.billingInfoState;
  }

  setApplication(applicationData: IApplication): void {
    this.applicationState = { ...applicationData };
    this.updateState();
  }

  get application(): IApplication {
    return this.applicationState;
  }

  get step(): string {
    return this.stepState;
  }

  setTariffPlan(tariffPlan: ITariffPlan): void {
    this.tariffPlanState = tariffPlan;
    this.updateState();
  }

  get tariffPlan(): ITariffPlan {
    return this.tariffPlanState;
  }

  setWizardState(stateData: any): void {
    const state = JSON.parse(localStorage.getItem(this.localStorageName));
    const dataBack = stateData.data;

    this.userInputDataValue = state?.userInputData;
    this.tariffPlanState = state?.tariffPlan;
    this.userState = dataBack?.user;
    this.companyState = dataBack?.company ? dataBack.company : state?.company ? state.company : null;
    this.billingInfoState = dataBack?.subscription ? dataBack.subscription : state?.billingInfo ? state.billingInfo : null;
    this.applicationState = dataBack?.application ? dataBack.application : state?.application ? state.application : null;
    this.stepState = dataBack?.step ? this.stepResolver(dataBack.step) : state?.step ? state.step : null;
  }

  getWizardState(): Observable<IBackendState> {
    return this.http.get(`${this.configService.apiUrlV2}/wizard`).pipe(map((response: IBackendState) => response));
  }

  removeLocalState(): void {
    localStorage.removeItem(this.localStorageName);
  }

  stepResolver(step: string): string {
    return step === 'company'
      ? '3'
      // : step === 'payment-profile'
      // ? '4'
      // : step === 'application' || step === 'subscription'
      // ? '5'
      : step === 'agreements' ? '4'
      : step === 'wasted' ? '5'
      : '';
  }
}
