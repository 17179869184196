import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigServiceInterface } from '../../../../core/services/config/config-service.interface';
import { InviteServiceInterface, IParams } from './invite.service.interface';

@Injectable()
export class InviteService implements InviteServiceInterface {
  public token: string;
  public email: string;
  public companyName: string;
  public country: string;

  constructor(@Inject('ConfigServiceInterface') private configService: ConfigServiceInterface, private http: HttpClient) {}

  public inviteCheck(params: IParams): Observable<any> {
    this.token = params.token;

    return this.http.post(`${this.configService.apiUrlV2}/company-invite`, { ...params }).pipe(
      map((response: any) => {
        this.email = response.data.email;
        this.companyName = response.data.company_name;
        this.country = response.data.country;
        return response.data;
      })
    );
  }

  getToken(): string {
    return this.token;
  }

  getEmail(): string {
    return this.email;
  }

  getCountry(): string {
    return this.country;
  }

  getCompanyName(): string {
    return this.companyName;
  }
}
